import {
  Button,
  Card,
  makeStyles,
  Fade,
  TextField,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NavDrawer from "./NavDrawer";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    justifyContent: "space-around",
    flexDirection: "column",
    width: "auto",
  },
  addUserTitle: {
    fontSize: 30,
    padding: theme.spacing(1),
    fontVariant: "small-caps",
    fontWeight: 900,
    letterSpacing: "2px",
    borderBottom: "2px solid #365680",
    width: "100%",
    color: "#365680",
    display: "flex",
    justifyContent: "flex-start",
  },
  addUserBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#365680",
    },
    marginTop: theme.spacing(2),
    width: "100%",
    letterSpacing: 2,
  },
  addUser: {
    // padding: theme.spacing(1),
    color: "#365680 !important",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  addUserInput: {
    color: "#365680 !important",
    letterSpacing: "2px",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: "#FF6B00",
    },
    "&:after": {
      borderBottomColor: "#FF6B00",
    },
    "&:hover:before": {
      borderBottomColor: "#FF6B00 !important",
    },
  },
  form: {
    width: 320,
    justifyContent: "center",
    alignItems: "center",
    background: "#F1F1F1",
    borderRadius: 12,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
  },
  card: {
    background: "#F1F1F1",
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  errorSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "20px 0px 0px 0px",
  },
  errorMessage: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
    color: "red",
  },
  errorIcon: {
    color: "red",
    margin: "0px 10px 0px 0px",
  },
  fieldIcons: {
    height: 24,
    width: 24,
    cursor: "none",
    pointerEvents: "none",
    color: "#FF6B00",
  },
}));
const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiInputLabel: {
        shrink: {
          color: "#FF6B00",
        },
      },
      MuiFormLabel: {
        root: {
          color: "#365780",
          "&.Mui-focused": {
            color: "#FF6B00",
          },
        },
      },
    },
  });

const AddUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userObj } = useLocation();
  const [error, setError] = useState(null);
  const [emailValid, setEmailValid] = useState(true);
  const [showEmailValidity, setShowEmailValidity] = useState(false);
  const [settingValue, setSettingValue] = useState(false);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    user_id: "",
  });

  const [editedUser, setEditedUser] = useState({
    user_id: "",
  });

  console.log("user object is ", userObj);

  useEffect(() => {
    if (userObj !== undefined || "") {
      user.firstName = userObj.firstName;
      user.lastName = userObj.lastName;
      user.email = userObj.email;
      user.password = userObj.password;
      user.user_id = userObj._id;
      editedUser.user_id = userObj._id;
      setSettingValue(true);
    }
  }, []);

  const emailValue = (e) => {
    let trimmedValue = e.target.value.trim();
    setUser({ ...user, email: trimmedValue });
    // console.log(trimmedValue);
    const validateEmail = (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    if (validateEmail(trimmedValue) === true && trimmedValue !== "") {
      // console.log("trueeeee");

      setEmailValid(true);
    } else {
      // console.log("falseeeeeeeee");

      setEmailValid(false);
    }
  };

  const handleEditUser = async () => {
    if (user.firstName !== "" || user.lastName !== "" || user.email !== "") {
      if (user.firstName !== userObj.firstName) {
        editedUser["firstName"] = user.firstName;
      }
      if (user.lastName !== userObj.lastName) {
        editedUser["lastName"] = user.lastName;
      }
      if (user.email !== userObj.email) {
        editedUser["email"] = user.email;
      }
      if (user.password !== userObj.password) {
        editedUser["password"] = user.password;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/users/edituser`,
          editedUser
        );
        console.log(response);

        switch (response.status) {
          case 201:
            console.log("user editted successfully.");
            alert("user editted successfully.");
            break;
          case 409:
            alert(response.data.errors);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }

        history.push("/users");
      } catch (error) {
        console.log(error.response);
        const errorMessage = error;
        alert(errorMessage);
      }
    } else {
      console.log("Fields must be filled");
      setError(true);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.main}>
          <Card className={classes.card} elevation={0}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <Typography className={classes.addUserTitle} variant="h4">
                Edit User's details
              </Typography>
              <form autoComplete={"off"} className={classes.form}>
                <TextField
                  type="text"
                  className={classes.addUser}
                  label="First Name"
                  value={user.firstName}
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.addUserInput,
                    },
                  }}
                  onChange={(e) => {
                    setUser({ ...user, firstName: e.target.value });
                  }}
                />
                <TextField
                  type="text"
                  className={classes.addUser}
                  label="Last Name"
                  value={user.lastName}
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.addUserInput,
                    },
                  }}
                  onChange={(e) => {
                    setUser({ ...user, lastName: e.target.value });
                  }}
                />
                <TextField
                  type="text"
                  className={classes.addUser}
                  label="Email Address"
                  value={user.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showEmailValidity ? (
                          emailValid === true ? (
                            <Fade in={emailValid}>
                              <CheckIcon className={classes.fieldIcons} />
                            </Fade>
                          ) : (
                            <Fade in={!emailValid}>
                              <CloseIcon className={classes.fieldIcons} />
                            </Fade>
                          )
                        ) : (
                          ""
                        )}
                      </InputAdornment>
                    ),
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.addUserInput,
                    },
                  }}
                  onChange={(e) => {
                    emailValue(e);
                  }}
                  onFocus={() => {
                    setShowEmailValidity(false);
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length > 0) {
                      setShowEmailValidity(true);
                    } else {
                      setShowEmailValidity(false);
                    }
                  }}
                />
                <TextField
                  type="password"
                  className={classes.addUser}
                  label="Enter New Password (Optional)"
                  // value={user.password}
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.addUserInput,
                    },
                  }}
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                  }}
                />
                <Button
                  // disabled={
                  //   user.firstName.length === 0 ||
                  //   user.lastName.length === 0 ||
                  //   user.email.length === 0 ||
                  //   emailValid === false
                  //   // ||
                  //   // user.password.length === 0
                  // }
                  variant="contained"
                  size="large"
                  className={classes.addUserBtn}
                  onClick={handleEditUser}
                >
                  Edit User
                </Button>
                {error && (
                  <Fade in={error}>
                    <>
                      <div className={classes.errorSection}>
                        <ReportProblemRoundedIcon
                          className={classes.errorIcon}
                        />
                        <Typography className={classes.errorMessage}>
                          User's detail editting Failed !!
                        </Typography>
                      </div>
                    </>
                  </Fade>
                )}
              </form>
            </MuiThemeProvider>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddUser;
