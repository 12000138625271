import http from "../http-common";

const upload = (folderName, file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);

  return http.post("api/file/upload/" + folderName, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const deleteFile = (folderName, fileName) => {
  return http.delete("api/file/delete/"+folderName+"/"+fileName);
}

const getFiles = (folderName) => {
  return http.get("api/file/files/"+folderName);
};

const FileUploadService = {
  upload,
  getFiles,
  deleteFile,
};

export default FileUploadService;
