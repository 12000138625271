import {
  Button,
  Card,
  makeStyles,
  Fade,
  TextField,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import NavDrawer from "./NavDrawer";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import MaterialDatatable from "material-datatable";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import iconPlaceholder from "../assets/images/logo-placeholder.png";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    justifyContent: "space-around",
    width: "auto",
    flex: "auto",
  },
  AddNotificationTitle: {
    fontSize: 30,
    padding: "10px 0px",
    fontVariant: "small-caps",
    fontWeight: 900,
    letterSpacing: "2px",
    borderBottom: "2px solid #365680",
    width: "100%",
    color: "#365680",
    display: "flex",
    justifyContent: "flex-start",
  },
  AddNotificationBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#365680",
    },
    marginTop: theme.spacing(2),
    width: "100%",
    letterSpacing: 2,
  },
  AddNotification: {
    // padding: theme.spacing(1),
    color: "#365680 !important",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  AddNotificationInput: {
    color: "#365680 !important",
    letterSpacing: "2px",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: "#FF6B00",
    },
    "&:after": {
      borderBottomColor: "#FF6B00",
    },
    "&:hover:before": {
      borderBottomColor: "#FF6B00 !important",
    },
  },
  form: {
    justifyContent: "center",
    alignItems: "center",
    background: "#F1F1F1",
    borderRadius: 12,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
  },
  card: {
    background: "#F1F1F1",
    padding: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  errorSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "20px 0px 0px 0px",
  },
  errorMessage: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
    color: "red",
  },
  errorIcon: {
    color: "red",
    margin: "0px 10px 0px 0px",
  },
  iconInput: {
    display: "none",
  },
  iconStyle: {
    height: 100,
    width: 100,
  },
  uploadBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    marginTop: theme.spacing(4),
    width: "100%",
    letterSpacing: 2,
  },
  editBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    letterSpacing: 2,
  },
}));
// const getMuiTheme = () =>
//   createMuiTheme({
//     overrides: {
//       MuiInputLabel: {
//         shrink: {
//           color: "#FF6B00",
//         },
//       },
//       MuiFormLabel: {
//         root: {
//           color: "#365780",
//           "&.Mui-focused": {
//             color: "#FF6B00",
//           },
//         },
//       },
//     },
//   });

const AddNotification = () => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState(null);

  const [notification, setNotification] = useState({
    title: "",
    body: "",
    author: localStorage.getItem("email"),
    services: [],
  });

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            color: "#365680",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
          // square: false,
        },
        MuiTableCell: {
          root: {
            background: "#365680 !Important",
            color: "#fff !Important",
            fontSize: 16,
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
        },
        MuiTable: {
          root: {
            background: "#365680 !Important",
          },
        },
        MuiIconButton: {
          root: {
            color: "#cdcdcd !Important",
          },
        },
        MuiSelect: { icon: { color: "#fff" } },
        MaterialDatatableBodyRow: {
          responsiveStacked: {
            border: "solid 1px #cdcdcd !Important",
          },
        },
        MuiInputBase: {
          input: {
            color: "#365680",
          },
        },
        MuiInput: {
          underline: {
            "&:after": {
              borderBottom: "2px solid #365680",
            },
          },
        },
        MaterialDatatableSearch: {
          searchIcon: {
            color: "#365680",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#fff !Important",
          },
        },
        MaterialDatatableHeadCell: {
          root: {
            color: "#fff !Important",
            fontSize: 20,
            fontFamily: "Montserrat !Important",
            fontVariant: "small-caps !Important",
            letterSpacing: "1px",
            fontWeight: 500,
          },
          sortActive: {
            color: "#fff !Important",
          },
          ".&:focus": {
            color: "#fff Important",
          },
        },
      },
    });

  const handleNewNotification = async () => {
    if (notification.title !== "" || notification.body !== "") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/push-notifications/add-pushNotification`,
          notification
        );
        console.log(response);

        switch (response.status) {
          case 201:
            history.push("/push-notifications");
            break;
          case 409:
            alert(response.data.message);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log("adding notification failed");
        setError(true);
        const errorMessage = error.response.data.message;
        alert(errorMessage);
      }
    } else {
      console.log("Fields must be filled");
      setError(true);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.main}>
          <Card className={classes.card} elevation={0}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <Typography className={classes.AddNotificationTitle} variant="h4">
                Add New Notification
              </Typography>
              <form autoComplete={"off"} className={classes.form}>
                <TextField
                  type="text"
                  className={classes.AddNotification}
                  label="Notification Title"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.AddNotificationInput,
                    },
                  }}
                  onChange={(e) => {
                    setNotification({
                      ...notification,
                      title: e.target.value,
                    });
                  }}
                />
                <textarea
                  type="text"
                  className={classes.AddNotification}
                  rows={10}
                  placeholder="Enter Push Notification Message here..."
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.AddNotificationInput,
                    },
                  }}
                  onChange={(e) => {
                    setNotification({
                      ...notification,
                      body: e.target.value,
                    });
                  }}
                />

                <Button
                  disabled={
                    notification.title.length === 0 ||
                    notification.body.length === 0 ||
                    notification.author.length === 0
                  }
                  variant="contained"
                  size="large"
                  className={classes.AddNotificationBtn}
                  onClick={handleNewNotification}
                >
                  Broadcast Notification
                </Button>
                {error && (
                  <Fade in={error}>
                    <>
                      <div className={classes.errorSection}>
                        <ReportProblemRoundedIcon
                          className={classes.errorIcon}
                        />
                        <Typography className={classes.errorMessage}>
                          Failed to send notification !!
                        </Typography>
                      </div>
                    </>
                  </Fade>
                )}
              </form>
            </MuiThemeProvider>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddNotification;
