import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import MobileDevicePreview from "sanity-mobile-preview";
// import SanityMobilePreview from 'sanity-mobile-preview'
import 'sanity-mobile-preview/dist/index.css?raw';

const Preview = () => {
  const location = useLocation();
  const [post, setPost] = useState();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      setPost(location.state);
    }
  }, [location.state])

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: 'column',
    }}>
      <div style={{
        textAlign: 'right',
        width: '100%',
      }}>
        <CloseIcon style={{padding: 12}} stroke={"black"} strokeWidth={2} fontSize={"large"} cursor={"pointer"} onClick={() => history.push({pathname: "/add-new-post", state: post})}/>
      </div>
      <div style={{
      }}>
        <MobileDevicePreview preSelectedDevice={"iphone-x"} preSelectedColor={"black"} allowedDevices={["iphone-x"]} showMenu={false}>
          <div style={{marginTop: 64, height: '100%', padding: 24}}>
          {/*  <img style={{*/}
          {/*  width: '100%',*/}
          {/*}} src={post ? post.articlePic : ''} />*/}
            <div dangerouslySetInnerHTML={{__html: post ? post.postContent : ''}}></div>
          </div>

        </MobileDevicePreview>
      </div>

    </div>
  );
}

export default Preview;
