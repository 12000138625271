import React, {useEffect, useState} from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  TableCell,
  Paper,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import "../assets/css/common.css";
import NavDrawer from "./NavDrawer";
import { Card } from "@material-ui/core";
import axios from "axios";
import moment from "moment";

const SPACED_DATE_FORMAT = "LLL";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    // fontFamily: "Montserrat !important",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    justifyContent: "space-around",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  dashTitle: {
    display: "flex",
    justifyContent: "center",
    fontVariant: "small-caps",
    fontWeight: 900,
    letterSpacing: "2px",
    color: "#FF6B00",
  },
  recentPublished: {
    background: "#36567F",
    width: 600,
    height: "fit-content",
    [theme.breakpoints.down("md")]: {
      marginBottom: 100,
    },
  },
  recentNotification: {
    background: "#36567F",
    width: 600,
    height: "fit-content",
  },
  subTitle: {
    fontSize: 25,
    padding: theme.spacing(2),
    fontVariant: "small-caps",
    fontWeight: 600,
    letterSpacing: "2px",
    borderBottom: "2px solid #fff",
    width: "100%",
    textAlign: "left",
    color: "#FFF",
  },
  listItem: { textAlignLast: "start" },
  listItemTitle: {
    width: 320,
    height: "auto",
  },
  list: { color: "#FFF" },
  table: {
    width: "100%",
    // background: "linear-gradient(45deg,#FF6B00,#ffb3d1) !important",
    background: "#EFEFEF",
    // [theme.breakpoints.down("md")]: {
    //   width: "60%",
    // },
  },
}));

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          borderBottom: "none !Important",
          fontFamily: "Montserrat !Important",
        },
        body: {
          color: "#fff",
        },

        head: {
          fontSize: 20,
        },
      },
    },
  });
const getMuiThemeStats = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          fontFamily: "Montserrat !Important",
        },
        body: {
          color: "#2B2828",
        },
        head: {
          color: "#000",
          fontSize: 16,
          padding: 16,
          fontVariant: "small-caps",
          fontWeight: 600,
          letterSpacing: "1px",
        },
      },
    },
  });

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      // background: "red",
    },
  },
}))(TableRow);

const Dashboard = () => {
  const classes = useStyles();
  const [pushNotificationArr, setPushNotificationArr] = useState([]);
  const [postsArr, setPostsArr] = useState([]);

  function createData(status, date, time, views, comments) {
    return { status, date, time, views, comments };
  }

  const rows = [
    createData("Moderate to Severe Allergic Reaction", 159, 6, 24, 40),
    createData("Supraglottic Airway", 237, 9, 37, 43),
    createData("Airway/Breathing", 262, 16, 24, 60),
    createData("Moderate to Severe Allergic Reaction", 305, 37, 67, 43),
    createData("Supraglottic Airway", 356, 160, 49, 39),
  ];
  function createNotifyData(date, time, title) {
    return { date, time, title };
  }

  const notifyData = [
    createNotifyData(
      "June 22nd",
      "6:40 PM",
      "Moderate to Severe Allergic Reaction"
    ),
    createNotifyData("June 22nd", "6:40 PM", "Supraglottic Airway"),
    createNotifyData("June 22nd", "6:40 PM", "Airway/Breathing"),
    createNotifyData(
      "June 22nd",
      "6:40 PM",
      "Moderate to Severe Allergic Reaction"
    ),
    createNotifyData("June 22nd", "6:40 PM", "Airway/Breathing"),
  ];

  function createRecentData(date, time, title) {
    return { date, time, title };
  }

  const recentData = [
    createRecentData(
      "June 22nd",
      "6:40 PM",
      "Moderate to Severe Allergic Reaction"
    ),
    createRecentData("June 22nd", "6:40 PM", "Supraglottic Airway"),
    createRecentData("June 22nd", "6:40 PM", "Airway/Breathing"),
    createRecentData(
      "June 22nd",
      "6:40 PM",
      "Moderate to Severe Allergic Reaction"
    ),
    createRecentData("June 22nd", "6:40 PM", "Airway/Breathing"),
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/push-notifications`)
      .then((res) => {
        // console.log(res.data);
        const allPushNotifications = res.data.pushNotification;
        console.log(allPushNotifications);
        if (allPushNotifications.length > 5) {
          allPushNotifications.length = 5;
        }
        setPushNotificationArr(allPushNotifications);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/posts`, {services: []})
      .then((res) => {
        // console.log(res.data);
        const allPost = res.data.post;
        console.log(allPost);
        if (allPost.length > 5) {
          allPost.length = 5;
        }
        setPostsArr(allPost);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={classes.root}>
      <NavDrawer />
      <div className={classes.content}>
        <div className={classes.main}>
          <Typography className={classes.dashTitle} variant="h4">
            Activity
          </Typography>
        </div>
        <div className={classes.main}>
          <Card elevation={10} className={classes.recentPublished}>
            <Typography className={classes.subTitle}>
              Recently Published
            </Typography>
            <MuiThemeProvider theme={getMuiThemeStats()}>
              <TableContainer elevation={10} component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow button>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {postsArr.map((row) => (
                      <StyledTableRow key={row._id}>
                        <TableCell align="left">{row.postTitle}</TableCell>
                        <TableCell align="left">{moment(new Date(row.date)).format(SPACED_DATE_FORMAT)}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </MuiThemeProvider>
          </Card>
          <Card elevation={10} className={classes.recentNotification}>
            <Typography className={classes.subTitle}>
              Recently Push Notification Broadcast
            </Typography>
            <MuiThemeProvider theme={getMuiThemeStats()}>
              <TableContainer elevation={10} component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow button>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pushNotificationArr.map((row) => (
                      <StyledTableRow key={row._id}>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{moment(new Date(row.date)).format(SPACED_DATE_FORMAT)}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </MuiThemeProvider>
          </Card>
        </div>

        {/*<div className={classes.main}>*/}
        {/*  <div>*/}
        {/*    <MuiThemeProvider theme={getMuiThemeStats()}>*/}
        {/*      <TableContainer elevation={10} component={Paper}>*/}
        {/*        <Table className={classes.table}>*/}
        {/*          <TableHead>*/}
        {/*            <TableRow button>*/}
        {/*              <TableCell>Stats</TableCell>*/}
        {/*              <TableCell align="center">Date</TableCell>*/}
        {/*              <TableCell align="center">Total Views</TableCell>*/}
        {/*              <TableCell align="center">Total Comments</TableCell>*/}
        {/*            </TableRow>*/}
        {/*          </TableHead>*/}
        {/*          <TableBody>*/}
        {/*            {rows.map((row) => (*/}
        {/*              <StyledTableRow key={row.status}>*/}
        {/*                <TableCell component="th" scope="row">*/}
        {/*                  {row.status}*/}
        {/*                </TableCell>*/}
        {/*                <TableCell align="left">{moment(new Date(row.date)).format(SPACED_DATE_FORMAT)}</TableCell>*/}
        {/*                <TableCell align="center">{row.views}</TableCell>*/}
        {/*                <TableCell align="center">{row.comments}</TableCell>*/}
        {/*              </StyledTableRow>*/}
        {/*            ))}*/}
        {/*          </TableBody>*/}
        {/*        </Table>*/}
        {/*      </TableContainer>*/}
        {/*    </MuiThemeProvider>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Dashboard;
