import "./App.css";
import Dashboard from "./components/Dashboard";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AddPost from "./components/AddPost";
import AllPosts from "./components/AllPosts";
import Hospitals from "./components/Hospitals";
import Services from "./components/Services";
import Categories from "./components/Categories";
import PushNotifications from "./components/PushNotifications";
import Users from "./components/Users";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import Comments from "./components/Comments";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import FA from "./components/FA";
import NewPassword from "./components/NewPassword";
import SignUp from "./components/SignUp";
import "./assets/css/font.css";
import EmailVerification from "./components/EmailVerification";
import AddUser from "./components/AddUser";
import AddHospital from "./components/AddHospital";
import AddService from "./components/AddService";
import AddCategory from "./components/AddCategory";
import AddNotification from "./components/AddNotification";
import EditUser from "./components/EditUser";
import Preview from "./components/Preview";
import SessionTimeout from "./components/SessionTimeout";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat !Important",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route exact path="/" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/f-a" component={FA} />
        <Route exact path="/reset" component={NewPassword} />
        <Route exact path="/all-posts" component={AllPosts} />
        <Route exact path="/hospitals" component={Hospitals} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/categories" component={Categories} />
        <Route exact path="/push-notifications" component={PushNotifications} />
        <Route exact path="/comments" component={Comments} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/add-new-post" component={AddPost} />
        <Route exact path="/preview" component={Preview} />
        <Route exact path="/verify" component={EmailVerification} />
        <Route exact path="/adduser" component={AddUser} />
        <Route exact path="/add-new-hospital" component={AddHospital} />
        <Route exact path="/add-new-service" component={AddService} />
        <Route exact path="/add-new-category" component={AddCategory} />
        <Route exact path="/add-new-notification" component={AddNotification} />
        <Route exact path="/edituser" component={EditUser} />
        <SessionTimeout />
      </Router>
    </ThemeProvider>
  );
}

export default App;
