import {
  Button,
  Card,
  makeStyles,
  Fade,
  TextField,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NavDrawer from "./NavDrawer";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import MaterialDatatable from "material-datatable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    justifyContent: "space-around",
    width: "auto",
    flex: "auto",
  },
  AddCategoryTitle: {
    fontSize: 30,
    padding: "10px 0px",
    fontVariant: "small-caps",
    fontWeight: 900,
    letterSpacing: "2px",
    borderBottom: "2px solid #365680",
    width: "100%",
    color: "#365680",
    display: "flex",
    justifyContent: "flex-start",
  },
  AddCategoryBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#365680",
    },
    marginTop: theme.spacing(2),
    width: "100%",
    letterSpacing: 2,
  },
  AddCategory: {
    // padding: theme.spacing(1),
    color: "#365680 !important",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  AddCategoryInput: {
    color: "#365680 !important",
    letterSpacing: "2px",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: "#FF6B00",
    },
    "&:after": {
      borderBottomColor: "#FF6B00",
    },
    "&:hover:before": {
      borderBottomColor: "#FF6B00 !important",
    },
  },
  form: {
    justifyContent: "center",
    alignItems: "center",
    background: "#F1F1F1",
    borderRadius: 12,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
  },
  card: {
    background: "#F1F1F1",
    padding: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  errorSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "20px 0px 0px 0px",
  },
  errorMessage: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
    color: "red",
  },
  errorIcon: {
    color: "red",
    margin: "0px 10px 0px 0px",
  },
  iconInput: {
    display: "none",
  },
  iconStyle: {
    height: 100,
    width: 100,
  },
  uploadBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    marginTop: theme.spacing(4),
    width: "100%",
    letterSpacing: 2,
  },
  editBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    letterSpacing: 2,
  },
}));
// const getMuiTheme = () =>
//   createMuiTheme({
//     overrides: {
//       MuiInputLabel: {
//         shrink: {
//           color: "#FF6B00",
//         },
//       },
//       MuiFormLabel: {
//         root: {
//           color: "#365780",
//           "&.Mui-focused": {
//             color: "#FF6B00",
//           },
//         },
//       },
//     },
//   });

const AddCategory = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [categoryArr, setCategoryArr] = useState([]);
  const [allCategoryArr, setAllCategoryArr] = useState([]);
  const location = useLocation();
  const initialState = {
    _id: "",
    categoryName: "",
    categoryParent: "",
    categoryColor: "#000",
    categoryIcon: "IconPlaceholder",
    author: localStorage.getItem("email"),
    order: '0',
  };
  const [updateCount, setUpdateCount] = useState(0);
  const [order, setOrder] = useState('0');

  const [category, setCategory] = useState(initialState);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/categories`)
      .then((res) => {
        const allcategories = res.data.category;
        let finalCategories = [];
        allcategories.forEach(category => {
          if (category.categoryParent === '') {
            finalCategories.push(category);
          }
        })
        console.log(allcategories);
        console.log('Final categories: ',finalCategories);
        setCategoryArr(finalCategories);
        setAllCategoryArr(allcategories);
      })
      .catch((error) => {
        console.log(error);
      });
    setCategory(initialState);
    setUpdateCount(prevState => prevState + 1);
  }, []);

  useEffect(() => {
    if (location.state) {
      setOrder(location.state.order ? location.state.order : "0");
      setCategory(location.state);
      setUpdateCount(prevState => prevState + 1);
    }
  }, [location.state]);

  const columns = [
    {
      name: "Category Name",
      field: "categoryName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      field: "action",
      options: {
        customBodyRender: value => {
          return (
            <>
              <Button
                style={{ height: "30px" }}
                variant="outlined"
                className={classes.editBtn}
                onClick={() => {
                  history.push("/add-new-category", value);
                }}
              >
                Edit
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    search: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    viewColumns: false,
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      array.forEach(({ rowIndex, dataIndex }) => {
        console.log(rowIndex, dataIndex);
        const category_id = categoryArr[dataIndex]._id;
        console.log("ID IS :", category_id);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}api/categories/delete-category/${category_id}`
          )
          .then((res) => {
            console.log("RESPONSE IS", res);
            console.log("Category Successfully deleted");
          });
      });
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
          // square: false,
        },
        MuiToolbar: {
          root: {
            background: "#f2f2f2",
          },
        },
        MuiTableCell: {
          root: {
            background: "#f2f2f2 !Important",
            color: "#000 !Important",
            fontSize: 16,
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
        },
        MuiTable: {
          root: {
            background: "#000 !Important",
          },
        },
        MuiIconButton: {
          root: {
            color: "#cdcdcd !Important",
          },
        },
        MuiSelect: { icon: { color: "#000" } },
        MaterialDatatableBodyRow: {
          responsiveStacked: {
            border: "solid 1px #cdcdcd !Important",
          },
        },
        MuiInputBase: {
          input: {
            color: "#000",
          },
        },
        MuiInput: {
          underline: {
            "&:after": {
              borderBottom: "2px solid #000",
            },
          },
        },
        MaterialDatatableSearch: {
          searchIcon: {
            color: "#365680",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#000 !Important",
          },
        },
        MaterialDatatableHeadCell: {
          root: {
            color: "#000 !Important",
            fontSize: 20,
            fontFamily: "Montserrat !Important",
            fontVariant: "small-caps !Important",
            letterSpacing: "1px",
            fontWeight: 500,
          },
          sortActive: {
            color: "#000 !Important",
          },
          ".&:focus": {
            color: "#000 Important",
          },
        },
      },
    });

  const handleNewCategory = async () => {
    if (
      category.categoryName !== "" ||
      category.categoryColor !== "" ||
      category.author !== ""
    ) {
      try {
        category.order = order;
        console.log('Category adding: ', category);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/categories/add-category`,
          category
        );
        console.log(response);
        // if (response.data.success === true) {
        //   console.log("Adding category successfully done.");
        //   history.push("/categories");
        // }

        switch (response.status) {
          case 201:
            console.log("Adding category successfully done.");
            history.push("/categories");
            break;
          case 409:
            alert(response.data.errors);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log("adding category failed");
        console.log(error);
        setError(true);
        const errorMessage = error;
        alert(errorMessage);
      }
    } else {
      console.log("Fields must be filled");
      setError(true);
    }
  };

  const getIndexOfCategory = categoryId => {
    let returnValue = 0;
    let found = false;
    for (let i = 0; i < categoryArr.length; i++) {
      if (categoryArr[i]._id === categoryId) {
        returnValue = i;
        found = true;
      }
    }
    if (!found) {
      if (categoryArr.length > 0) {
        setCategory({
          ...category,
          categoryParent: categoryArr[0]._id,
        });
      }
    }
    return returnValue;
  }

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.main}>
          <Card className={classes.card} elevation={0}>
            <MuiThemeProvider theme={getMuiTheme()}>
              {category && (
                <div key={`full-${updateCount}`}>
                <Typography className={classes.AddCategoryTitle} variant="h4">
                  {category._id !== '' ? 'Update' : 'Add New'} Category
                </Typography>
                <form autoComplete={"off"} className={classes.form}>
                <TextField
                type="text"
                className={classes.AddCategory}
                label="Category Name"
                defaultValue={category.categoryName}
                value={category.categoryName}
                InputProps={{
                classes: {
                underline: classes.textFieldUnderline,
                input: classes.AddCategoryInput,
              },
              }}
                onChange={(e) => {
                setCategory({ ...category, categoryName: e.target.value });
              }}
                />
                  <TextField style={{width: '100%'}} label="Order" type="number" value={order} onChange={(e) => {
                    console.log('Update value', e.target.value);
                    setCategory({
                      ...category,
                      order: e.target.value,
                    });
                    setOrder(e.target.value);
                  }} />
                <>
                <Autocomplete
                key={`category-parent-${updateCount}`}
                className={classes.AddCategory}
                options={categoryArr}
                defaultValue={(categoryArr.length > 0 && category.categoryParent !== '') ? categoryArr[getIndexOfCategory(category.categoryParent)] : null}
                value={(categoryArr.length > 0 && category.categoryParent !== '') ? categoryArr[getIndexOfCategory(category.categoryParent)] : null}
                getOptionLabel={(option) => option.categoryName}
                onChange={(e) => {
                  console.log('Selected Parent Cate: ', e.target.innerHTML);
                  const getCategoryId = categoryName => {
                    let id = 0;
                    categoryArr.forEach(category => {
                      if (category.categoryName === categoryName) {
                        id = category._id;
                      }
                    });
                    return id;
                  }
                  if (e.target.innerHTML.charAt(0) === '<') {
                    setCategory({
                      ...category,
                      categoryParent: '',
                    });
                  } else {
                    if (getCategoryId(e.target.innerHTML) !== category._id) {
                      setCategory({
                        ...category,
                        categoryParent: getCategoryId(e.target.innerHTML),
                      });
                    }
                  }
                setUpdateCount(prevState => prevState + 1);
              }}
                renderInput={(params) => (
                <TextField
              {...params}
                variant="standard"
                label="Select Category Parent"
                placeholder="Select Category Parent"
                />
                )}
                />
                <TextField
                type="color"
                className={classes.AddCategory}
                label="Category Color"
                value={category.categoryColor}
                defaultValue={category.categoryColor}
                InputProps={{
                classes: {
                underline: classes.textFieldUnderline,
                input: classes.AddCategoryInput,
              },
              }}
                onChange={(e) => {
                console.log(e.target.value);
                setCategory({
                ...category,
                categoryColor: e.target.value,
              });
              }}
                list="TextFieldColors"
                />
                </>



              {/*<div>*/}
              {/*  <input type="color" list="presetColors" />*/}
              {/*  <datalist id="presetColors">*/}
              {/*    <option value="#ff0000" />*/}
              {/*    <option value="#00ff00" />*/}
              {/*    <option value="#0000ff" />*/}
              {/*  </datalist>*/}
              {/*</div>*/}


                <datalist id="TextFieldColors">
                <option value="#ff0000" />
                <option value="#00ff00" />
                <option value="#0000ff" />
                </datalist>

                <Button
                disabled={
                category.categoryName.length === 0 ||
                category.categoryColor.length === 0
              }
                variant="contained"
                size="large"
                className={classes.AddCategoryBtn}
                onClick={handleNewCategory}
                >
              {category._id !== '' ? 'Save Changes' : 'Add Category'}
                </Button>
              {error && (
                <Fade in={error}>
                <>
                <div className={classes.errorSection}>
                <ReportProblemRoundedIcon
                className={classes.errorIcon}
                />
                <Typography className={classes.errorMessage}>
                Category {category._id !== '' ? 'Update' : 'Addition'} Failed !!
                </Typography>
                </div>
                </>
                </Fade>
                )}
                </form>
                </div>
              )}
            </MuiThemeProvider>
          </Card>

          <Card
            style={{
              backgroundColor: "#fafafa",
            }}
            elevation={0}
          >
            <MuiThemeProvider theme={getMuiTheme()}>
              <MaterialDatatable
                title={"All Categories List"}
                data={allCategoryArr}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
