import {
  Button,
  Card,
  makeStyles,
  Fade,
  TextField,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import NavDrawer from "./NavDrawer";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import MaterialDatatable from "material-datatable";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import iconPlaceholder from "../assets/images/logo-placeholder.png";
import axios from "axios";
import {useHistory, useLocation} from "react-router-dom";
import { Convert } from "mongo-image-converter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    justifyContent: "space-around",
    width: "auto",
    flex: "auto",
  },
  addHospitalTitle: {
    fontSize: 30,
    padding: "10px 0px",
    fontVariant: "small-caps",
    fontWeight: 900,
    letterSpacing: "2px",
    borderBottom: "2px solid #365680",
    width: "100%",
    color: "#365680",
    display: "flex",
    justifyContent: "flex-start",
  },
  addHospitalBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#365680",
    },
    marginTop: theme.spacing(2),
    width: "100%",
    letterSpacing: 2,
  },
  addHospital: {
    // padding: theme.spacing(1),
    color: "#365680 !important",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  addHospitalInput: {
    color: "#365680 !important",
    letterSpacing: "2px",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: "#FF6B00",
    },
    "&:after": {
      borderBottomColor: "#FF6B00",
    },
    "&:hover:before": {
      borderBottomColor: "#FF6B00 !important",
    },
  },
  form: {
    justifyContent: "center",
    alignItems: "center",
    background: "#F1F1F1",
    borderRadius: 12,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
  },
  card: {
    background: "#F1F1F1",
    padding: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  errorSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "20px 0px 0px 0px",
  },
  errorMessage: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
    color: "red",
  },
  errorIcon: {
    color: "red",
    margin: "0px 10px 0px 0px",
  },
  iconInput: {
    display: "none",
  },
  iconStyle: {
    height: 100,
    width: 100,
  },
  uploadBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    marginTop: theme.spacing(4),
    width: "100%",
    letterSpacing: 2,
  },
  editBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    letterSpacing: 2,
  },
  helperText: {
    fontSize: 11,
    padding: "10px 0px 0px 0px",
    margin: 0,
    letterSpacing: 1,
    color: "#365680",
    fontStyle: "italic",
    width: "100%",
    opacity: 0.8,
  },
}));

const AddHospital = () => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [hospitalArr, setHospitalArr] = useState([]);
  const initialState = {
    _id: "",
    hospitalName: "",
    hospitalIcon: iconPlaceholder,
    hospitalIconHeight: 234,
    hospitalIconWidth: 234,
    author: localStorage.getItem("email"),
  };
  const [hospital, setHospital] = useState(initialState);
  const [updateCount, setUpdateCount] = useState(0);
  const location = useLocation();
  const hospitalImageRef = useRef(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/base-hospitals`)
      .then((res) => {
        console.log(res.data);
        const allHospitals = res.data.hospitals;
        console.log(allHospitals);
        setHospitalArr(allHospitals);
      })
      .catch((error) => {
        console.log(error);
      });
    setHospital(initialState);
    setUpdateCount(prevState => prevState + 1);
  }, []);

  useEffect(() => {
    if (location.state) {
      setHospital(location.state);
      setUpdateCount(prevState => prevState + 1);
    }
  }, [location.state]);

  const columns = [
    {
      name: "Hospital Name",
      field: "hospitalName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      field: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                style={{ height: "30px" }}
                variant="outlined"
                className={classes.editBtn}
                onClick={() => {
                  history.push("/add-new-hospital", value);
                }}
              >
                Edit
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    search: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    viewColumns: false,
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      array.forEach(({ rowIndex, dataIndex }) => {
        console.log(rowIndex, dataIndex);
        const hospital_id = hospitalArr[dataIndex]._id;
        console.log("ID IS :", hospital_id);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}api/base-hospitals/delete-hospital/${hospital_id}`
          )
          .then((res) => {
            console.log("RESPONSE IS", res);
            console.log("Base hospital Successfully deleted");
          });
      });
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
          // square: false,
        },
        MuiToolbar: {
          root: {
            background: "#f2f2f2",
          },
        },
        MuiTableCell: {
          root: {
            background: "#f2f2f2 !Important",
            color: "#000 !Important",
            fontSize: 16,
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
        },
        MuiTable: {
          root: {
            background: "#000 !Important",
          },
        },
        MuiIconButton: {
          root: {
            color: "#cdcdcd !Important",
          },
        },
        MuiSelect: { icon: { color: "#000" } },
        MaterialDatatableBodyRow: {
          responsiveStacked: {
            border: "solid 1px #cdcdcd !Important",
          },
        },
        MuiInputBase: {
          input: {
            color: "#000",
          },
        },
        MuiInput: {
          underline: {
            "&:after": {
              borderBottom: "2px solid #000",
            },
          },
        },
        MaterialDatatableSearch: {
          searchIcon: {
            color: "#365680",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#000 !Important",
          },
        },
        MaterialDatatableHeadCell: {
          root: {
            color: "#000 !Important",
            fontSize: 20,
            fontFamily: "Montserrat !Important",
            fontVariant: "small-caps !Important",
            letterSpacing: "1px",
            fontWeight: 500,
          },
          sortActive: {
            color: "#000 !Important",
          },
        },
      },
    });

  const imgValue = async (e) => {
    console.log(e.target.files[0]);
    try {
      const convertedImage = await Convert(e.target.files[0]);
      if (convertedImage) {
        // console.log(convertedImage);
        setHospital({ ...hospital, hospitalIcon: convertedImage });
      } else {
        alert("The file is not in .png format of image");
      }
    } catch (error) {
      console.warn(error.message);
    }
  };

  const handleNewHospital = async () => {
    if (hospital.hospitalName !== "" || hospital.hospitalIcon !== "") {
      try {
        hospital.hospitalIconHeight = hospitalImageRef.current.clientHeight;
        hospital.hospitalIconWidth = hospitalImageRef.current.clientWidth;
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/base-hospitals/add-hospital`,
          hospital
        );
        console.log(response);

        switch (response.status) {
          case 201:
            console.log("Adding Hospital successfully done.");
            history.push("/hospitals");
            break;
          case 409:
            alert(response.data.errors);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log("adding Hospital failed");
        console.log(error);
        setError(true);
        const errorMessage = error;
        alert(errorMessage);
      }
    } else {
      console.log("Fields must be filled");
      setError(true);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.main}>
          <Card className={classes.card} elevation={0}>
            <MuiThemeProvider theme={getMuiTheme()}>
              {hospital && (
                <div key={`hospital-${updateCount}`}>
                  <Typography className={classes.addHospitalTitle} variant="h4">
                    {hospital._id !== '' ? 'Update' : 'Add New'} Base Hospital
                  </Typography>
                  <form autoComplete={"off"} className={classes.form}>
                    <TextField
                      type="text"
                      className={classes.addHospital}
                      label="Base Hospital Name"
                      defaultValue={hospital.hospitalName}
                      value={hospital.hospitalName}
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.addHospitalInput,
                        },
                      }}
                      onChange={(e) => {
                        setHospital({ ...hospital, hospitalName: e.target.value });
                      }}
                    />
                    {/* <TextField
                  type="text"
                  className={classes.addHospital}
                  label="Hospital Icon"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.addHospitalInput,
                    },
                  }}
                  onChange={(e) => {
                    setHospital({ ...hospital, hospitalIcon: e.target.value });
                  }}
                /> */}

                    <img
                     // className={classes.iconStyle}
                      alt="icon"
                      src={hospital.hospitalIcon}
                      ref={hospitalImageRef}
                    />
                    <input
                      accept="image/png"
                      name="hospitalIcon"
                      className={classes.iconInput}
                      id="hospitalIconId"
                      multiple
                      type="file"
                      onChange={(e) => {
                        imgValue(e);
                      }}
                    />

                    <label htmlFor="hospitalIconId">
                      <Button
                        startIcon={<PhotoCamera />}
                        variant="contained"
                        className={classes.uploadBtn}
                        component="span"
                      >
                        Upload Base Hospital Icon
                      </Button>
                    </label>
                    <div>
                      <p className={classes.helperText}>* .png format only</p>
                      <p className={classes.helperText}>
                        * Image dimensions must be at least 234px X 234px
                      </p>
                    </div>
                    <Button
                      disabled={
                        hospital.hospitalName.length === 0 ||
                        hospital.hospitalIcon.length === 0
                      }
                      variant="contained"
                      size="large"
                      className={classes.addHospitalBtn}
                      onClick={handleNewHospital}
                    >
                      {hospital._id !== '' ? 'Save Changes' : 'Add Hospital'}
                    </Button>
                    {error && (
                      <Fade in={error}>
                        <>
                          <div className={classes.errorSection}>
                            <ReportProblemRoundedIcon
                              className={classes.errorIcon}
                            />
                            <Typography className={classes.errorMessage}>
                              Hospital {hospital._id !== '' ? 'Update' : 'Addition'} Failed !!
                            </Typography>
                          </div>
                        </>
                      </Fade>
                    )}
                  </form>
                </div>
              )}
            </MuiThemeProvider>
          </Card>

          <Card
            style={{
              backgroundColor: "#fafafa",
            }}
            elevation={0}
          >
            <MuiThemeProvider theme={getMuiTheme()}>
              <MaterialDatatable
                title={"Base Hospitals List"}
                data={hospitalArr}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddHospital;
