import {
  Button,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import MaterialDatatable from "material-datatable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import NavDrawer from "./NavDrawer";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    // marginBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    cursor: "pointer",
    letterSpacing: 2,
    "&:hover": {
      background: "#36567F !important",
    },
  },
  usersTitle: {
    fontWeight: 900,
    fontVariant: "small-caps",
    letterSpacing: "2px",
    color: "#FF6000",
  },
  editBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    letterSpacing: 2,
  },
}));

const Users = () => {
  const classes = useStyles();
  const history = useHistory();
  const [userArr, setUserArr] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}api/users/all-users`).then((res) => {
      const allUsers = res.data.users;
      allUsers.forEach((user) => {
        if (!user.hasOwnProperty("firstName")) {
          user.firstName = "";
        }
        if (!user.hasOwnProperty("lastName")) {
          user.lastName = "";
        }
        if (!user.hasOwnProperty("post")) {
          user.post = 0;
        }
      });
      console.log(allUsers);
      setUserArr(allUsers);
    });
  }, []);

  const options = {
    filter: false,
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      if (
        window.confirm("Are you sure you would like to delete these users?")
      ) {
        array.forEach(({ rowIndex, dataIndex }) => {
          console.log(rowIndex, dataIndex);
          const user_id = userArr[dataIndex]._id;
          console.log("ID IS :", user_id);
          axios
            .post(`${process.env.REACT_APP_BASE_URL}api/users/deleteuser/${user_id}`)
            .then((res) => {
              console.log("RESPONSE IS", res);
              console.log("Use Successfully deleted");
            });
        });
      }
    },
  };

  const columns = [
    {
      name: "First Name",
      field: `firstName`,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Last Name",
      field: `lastName`,
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "Email",
      field: "email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Post",
      field: "post",
      options: { filter: true, sort: false },
    },
    {
      name: "Action",
      field: "action",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Button
                style={{ height: "30px" }}
                variant="outlined"
                className={classes.editBtn}
                onClick={() => {
                  console.log(value);
                  const userObj = value;
                  history.push({
                    pathname: "/edituser",
                    userObj,
                  });
                }}
              >
                Edit
              </Button>
            </>
          );
        },
      },
    },
  ];

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            fontFamily: "Montserrat !Important",
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
        },
        MuiTableCell: {
          root: {
            fontSize: 16,
            fontVariant: "small-caps",
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbar: {
          root: {
            fontFamily: "Montserrat !Important",
            background: "#F2F2F2",
          },
        },

        MuiTableHead: {
          root: {
            fontWeight: 700,
            fontSize: 16,
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
          title: {
            color: "#000 !important",
          },
        },

        MuiIconButton: {
          root: {
            color: "#2B2828",
          },
        },
      },
    });

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.content}>
          <div className={classes.main}>
            <Typography className={classes.usersTitle} variant="h4">
              User Management
            </Typography>
            <Button
              variant="contained"
              size="large"
              className={classes.addBtn}
              onClick={() => {
                history.push("/adduser");
              }}
            >
              + New User
            </Button>
          </div>
          <div className={classes.main}>
            <div style={{ width: "100%" }}>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MaterialDatatable
                  title={"Users"}
                  data={userArr}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Users;
