import {
  Button,
  Card,
  makeStyles,
  Fade,
  TextField,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NavDrawer from "./NavDrawer";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import MaterialDatatable from "material-datatable";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import iconPlaceholder from "../assets/images/logo-placeholder.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import placeholderImage from "../assets/images/iconPlaceholder.png";
import { Convert } from "mongo-image-converter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    justifyContent: "space-around",
    width: "auto",
    flex: "auto",
  },
  AddServiceTitle: {
    fontSize: 30,
    padding: "10px 0px",
    fontVariant: "small-caps",
    fontWeight: 900,
    letterSpacing: "2px",
    borderBottom: "2px solid #365680",
    width: "100%",
    color: "#365680",
    display: "flex",
    justifyContent: "flex-start",
  },
  AddServiceBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#365680",
    },
    marginTop: theme.spacing(2),
    width: "100%",
    letterSpacing: 2,
  },
  AddService: {
    // padding: theme.spacing(1),
    color: "#365680 !important",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  AddServiceInput: {
    color: "#365680 !important",
    letterSpacing: "2px",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: "#FF6B00",
    },
    "&:after": {
      borderBottomColor: "#FF6B00",
    },
    "&:hover:before": {
      borderBottomColor: "#FF6B00 !important",
    },
  },
  form: {
    justifyContent: "center",
    alignItems: "center",
    background: "#F1F1F1",
    borderRadius: 12,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
  },
  card: {
    background: "#F1F1F1",
    padding: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  errorSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "20px 0px 0px 0px",
  },
  errorMessage: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
    color: "red",
  },
  errorIcon: {
    color: "red",
    margin: "0px 10px 0px 0px",
  },
  iconInput: {
    display: "none",
  },
  iconStyle: {
    height: 100,
    width: 100,
  },
  uploadBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    marginTop: theme.spacing(4),
    width: "100%",
    letterSpacing: 2,
  },
  editBtn: {
    backgroundColor: "#FF6B00",
    color: "#FFF",
    textTransform: "none !Important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF6B00",
    },
    letterSpacing: 2,
  },
  helperText: {
    fontSize: 11,
    padding: "10px 0px 0px 0px",
    margin: 0,
    letterSpacing: 1,
    color: "#365680",
    fontStyle: "italic",
    width: "100%",
    opacity: 0.8,
  },
}));

const AddService = () => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [serviceArr, setServiceArr] = useState([]);
  const [baseHospitalArr, setBaseHospitalArr] = useState([]);
  const location = useLocation();
  const initialState = {
    _id: "",
    serviceName: "",
    serviceIcon: "",
    author: localStorage.getItem("email"),
    baseHospital: "",
  };
  const [updateCount, setUpdateCount] = useState(0);
  const [service, setService] = useState(initialState);

  useEffect(() => {
    // axios
    //   .get(`${process.env.REACT_APP_BASE_URL}api/services`)
    //   .then((res) => {
    //     const allServices = res.data.service;
    //     console.log(allServices);
    //     setServiceArr(allServices);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/base-hospitals`)
      .then((res) => {
        // console.log(res.data.hospitals);
        const allHospitals = res.data.hospitals;
        // console.log(allHospitals);
        setBaseHospitalArr(allHospitals);
      })
      .catch((error) => {
        console.log(error);
      });
    setService(initialState);
    setUpdateCount(prevState => prevState + 1);
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.value !== '') {
        setService(location.state.value);
      }
      if (location.state.services) {
        setServiceArr(location.state.services);
      }
      setUpdateCount(prevState => prevState + 1);
    }
  }, [location.state]);

  const columns = [
    {
      name: "Service Name",
      field: "serviceName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      field: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                style={{ height: "30px" }}
                variant="outlined"
                className={classes.editBtn}
                onClick={() => {
                  history.push("/add-new-service", value);
                }}
              >
                Edit
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    search: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    viewColumns: false,
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      array.forEach(({ rowIndex, dataIndex }) => {
        console.log(rowIndex, dataIndex);
        const service_id = serviceArr[dataIndex]._id;
        console.log("ID IS :", service_id);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}api/services/delete-service/${service_id}`
          )
          .then((res) => {
            console.log("RESPONSE IS", res);
            console.log("Service Successfully deleted");
          });
      });
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
          // square: false,
        },
        MuiToolbar: {
          root: {
            background: "#f2f2f2",
          },
        },
        MuiTableCell: {
          root: {
            background: "#f2f2f2 !Important",
            color: "#000 !Important",
            fontSize: 16,
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
        },
        MuiTable: {
          root: {
            background: "#000 !Important",
          },
        },
        MuiIconButton: {
          root: {
            color: "#cdcdcd !Important",
          },
        },
        MuiSelect: { icon: { color: "#000" } },
        MaterialDatatableBodyRow: {
          responsiveStacked: {
            border: "solid 1px #cdcdcd !Important",
          },
        },
        MuiInputBase: {
          input: {
            color: "#000",
          },
        },
        MuiInput: {
          underline: {
            "&:after": {
              borderBottom: "2px solid #000",
            },
          },
        },
        MaterialDatatableSearch: {
          searchIcon: {
            color: "#365680",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#000 !Important",
          },
        },
        MaterialDatatableHeadCell: {
          root: {
            color: "#000 !Important",
            fontSize: 20,
            fontFamily: "Montserrat !Important",
            fontVariant: "small-caps !Important",
            letterSpacing: "1px",
            fontWeight: 500,
          },
          sortActive: {
            color: "#000 !Important",
          },
          ".&:focus": {
            color: "#000 Important",
          },
        },
      },
    });

  const imgValue = async (e) => {
    console.log(e.target.files[0]);
    try {
      const convertedImage = await Convert(e.target.files[0]);
      if (convertedImage) {
        // console.log(convertedImage);
        setService({ ...service, serviceIcon: convertedImage });
      } else {
        alert("The file is not in .png format of image");
      }
    } catch (error) {
      console.warn(error.message);
    }
  };

  const getBaseHospitalId = hospital => {
    let id = '';
    baseHospitalArr.forEach(baseHospital => {
      console.log('Checking ==', baseHospital);
      console.log('With--', hospital);
      if (baseHospital.hospitalName === hospital) {
        id = baseHospital._id;
      }
    });
    console.log('ID is ', id);
    return id;
  }

  const selectHospitalVal = (event, value, reason) => {
    if (reason === 'remove-option') {
      setService({ ...service, baseHospital: '' });
    } else {
      console.log('Changing Base hospital: ', event.target.innerHTML);
      setService({ ...service, baseHospital:getBaseHospitalId(event.target.innerHTML) });
    }
  };

  const handleNewService = async () => {
    console.log('Trying to save this ', service);
    if (
      service.serviceName !== "" ||
      service.serviceIcon !== "" ||
      service.baseHospital !== "" ||
      service.author !== ""
    ) {
      try {
        service.baseHospital = getBaseHospitalId(service.baseHospital) !== '' ? getBaseHospitalId(service.baseHospital) : service.baseHospital;
        console.log('SERVICE: ', JSON.stringify(service));
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/services/add-service`,
          service
        );
        console.log(response);

        switch (response.status) {
          case 201:
            console.log("Adding service successfully done.");
            history.push("/services");
            break;
          case 409:
            alert(response.data.errors);
            break;
          case 500:
            alert(response);
            break;
          case 400:
            alert(response);
            break;
          case 202:
            alert(response);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log("adding service failed");
        console.log(error);
        setError(true);
        const errorMessage = error;
        alert(errorMessage);
      }
    } else {
      console.log("Fields must be filled");
      setError(true);
    }
  };

  const getIndexOfBaseHospital = hospital => {
    let returnValue = 0;
    for (let i = 0; i < baseHospitalArr.length; i++) {
      if (baseHospitalArr[i]._id === hospital) {
        returnValue = i;
      }
    }
    return returnValue;
  }

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.main}>
          <Card className={classes.card} elevation={0}>
            <MuiThemeProvider theme={getMuiTheme()}>
              {service && (
                <div key={`service-${updateCount}`}>
                  <Typography className={classes.AddServiceTitle} variant="h4">
                    {service._id !== '' ? 'Update' : 'Add New'} service
                  </Typography>
                  <form autoComplete={"off"} className={classes.form}>
                    <TextField
                      type="text"
                      className={classes.AddService}
                      label="Service Name"
                      defaultValue={service.serviceName}
                      value={service.serviceName}
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.AddServiceInput,
                        },
                      }}
                      onChange={(e) => {
                        setService({ ...service, serviceName: e.target.value });
                      }}
                    />

                    <Autocomplete
                      className={classes.AddService}
                      options={baseHospitalArr}
                      defaultValue={baseHospitalArr.length > 0 && service.baseHospital ? baseHospitalArr[getIndexOfBaseHospital(service.baseHospital)] : null}
                      value={(baseHospitalArr.length > 0 && service.baseHospital) ? baseHospitalArr[getIndexOfBaseHospital(service.baseHospital)] : null}
                      getOptionLabel={(option) => option.hospitalName}
                      onChange={selectHospitalVal}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select Base Hospital"
                          placeholder="Select Base Hospital"
                        />
                      )}
                    />

                    <img
                      className={classes.iconStyle}
                      alt="icon"
                      src={service.serviceIcon !== "" ? service.serviceIcon : iconPlaceholder}
                    />
                    <input
                      accept="image/*"
                      className={classes.iconInput}
                      id="serviceIconId"
                      multiple
                      type="file"
                      onChange={(e) => {
                        imgValue(e);
                      }}
                    />
                    <label htmlFor="serviceIconId">
                      <Button
                        startIcon={<PhotoCamera />}
                        variant="contained"
                        className={classes.uploadBtn}
                        component="span"
                      >
                        Upload Service Icon
                      </Button>
                    </label>
                    <div>
                      <p className={classes.helperText}>.png format only*</p>
                      <p className={classes.helperText}>
                        Image dimensions must be at least 234px X 234px*
                      </p>
                    </div>
                    <Button
                      disabled={
                        service.serviceName.length === 0 ||
                        service.serviceIcon.length === 0
                      }
                      variant="contained"
                      size="large"
                      className={classes.AddServiceBtn}
                      onClick={handleNewService}
                    >
                      {service._id !== '' ? 'Save Changes' : 'Add Service'}
                    </Button>
                    {error && (
                      <Fade in={error}>
                        <>
                          <div className={classes.errorSection}>
                            <ReportProblemRoundedIcon
                              className={classes.errorIcon}
                            />
                            <Typography className={classes.errorMessage}>
                              Service {service._id !== '' ? 'Update' : 'Addition'} Failed !!
                            </Typography>
                          </div>
                        </>
                      </Fade>
                    )}
                  </form>
                </div>
              )}
            </MuiThemeProvider>
          </Card>

          <Card
            style={{
              backgroundColor: "#fafafa",
            }}
            elevation={0}
          >
            <MuiThemeProvider theme={getMuiTheme()}>
              <MaterialDatatable
                title={"Servies List"}
                data={serviceArr}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddService;
