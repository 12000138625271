import {
  Button,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import MaterialDatatable from "material-datatable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import NavDrawer from "./NavDrawer";
import logo from "../assets/images/logo.png";
import axios from "axios";
import moment from 'moment'

const SPACED_DATE_FORMAT = "DD MMM YYYY";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    // marginBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    cursor: "pointer",
    letterSpacing: 2,
    "&:hover": {
      background: "#36567F !important",
    },
  },
  serviceTitle: {
    fontWeight: 900,
    fontVariant: "small-caps",
    letterSpacing: "2px",
    color: "#FF6000",
  },
}));

const Services = () => {
  const classes = useStyles();
  const history = useHistory();

  const [serviceArr, setServiceArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/services`, {hospitals: []})
      .then((res) => {
        const allServices = res.data.service;
        setIsLoading(false);
        setServiceArr(allServices);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = [
    {
      name: "Services",
      field: "serviceName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Icon",
      field: "serviceIcon",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <img src={value.serviceIcon} width="50" height="50" alt="logo" />
            </>
          );
        },
      },
    },
    {
      name: "Author",
      field: "author",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Date",
      field: "date",
      options: { filter: true, sort: false, customBodyRender: value =>
          moment(new Date(value.date)).format(SPACED_DATE_FORMAT) },
    },
    {
      name: "Actions",
      field: "action",
      options: {
        customBodyRender: value => {
          return (
            <>
              <Button
                style={{height: "30px"}}
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push({pathname: "/add-new-service", state: {value: value, services: serviceArr}});
                }}
              >
                Edit
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      array.forEach(({ rowIndex, dataIndex }) => {
        console.log(rowIndex, dataIndex);
        const service_id = serviceArr[dataIndex]._id;
        console.log("ID IS :", service_id);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}api/services/delete-service/${service_id}`
          )
          .then((res) => {
            console.log("RESPONSE IS", res);
            console.log("Service Successfully deleted");
          });
      });
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            fontFamily: "Montserrat !Important",
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
        },
        MuiTableCell: {
          root: {
            fontSize: 16,
            fontVariant: "small-caps",
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbar: {
          root: {
            fontFamily: "Montserrat !Important",
            background: "#F2F2F2",
          },
        },

        MuiTableHead: {
          root: {
            fontWeight: 700,
            fontSize: 16,
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
          title: {
            color: "#000 !important",
          },
        },

        MuiIconButton: {
          root: {
            color: "#2B2828",
          },
        },
      },
    });

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.content}>
          <div className={classes.main}>
            <Typography className={classes.serviceTitle} variant="h4">
              Service Management
            </Typography>
            <Button
              variant="contained"
              size="large"
              className={classes.addBtn}
              onClick={() => {
                history.push({pathname: "/add-new-service", state: {value: '', services: serviceArr}});
              }}
            >
              Add New Service
            </Button>
          </div>
          <div className={classes.main}>
            <div style={{ width: "100%" }}>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MaterialDatatable
                  title={isLoading ? "Loading all services..." : "All Services"}
                  data={serviceArr}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Services;
