import {
  Button,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import MaterialDatatable from "material-datatable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import NavDrawer from "./NavDrawer";
import axios from "axios";
import moment from "moment";

const SPACED_DATE_FORMAT = "DD MMM YYYY";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(6),
    boxSizing: "initial",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(0)} auto`,
    paddingTop: theme.spacing(4),
    // marginBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addBtn: {
    background: "#FF6B00 !important",
    color: "#fff",
    "&:hover": {
      background: "#FF6B00 !important",
    },
  },
  pushNotifyTitle: {
    fontWeight: 900,
    fontVariant: "small-caps",
    letterSpacing: "2px",
    color: "#FF6000",
  },
}));

const PushNotifications = () => {
  const classes = useStyles();
  const history = useHistory();
  const [pushNotificationArr, setPushNotificationArr] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/push-notifications`)
      .then((res) => {
        // console.log(res.data);
        const allPushNotifications = res.data.pushNotification;
        console.log(allPushNotifications);
        setPushNotificationArr(allPushNotifications);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = [
    {
      name: "Push Notification",
      field: "title",
      options: {
        // width: "400px",
        filter: true,
        sort: true,
      },
    },
    {
      name: "Message",
      field: "body",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Author",
      field: "author",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Date",
      field: "date",
      options: { filter: true, sort: false, customBodyRender: value =>
          moment(new Date(value.date)).format(SPACED_DATE_FORMAT) },
    },
  ];

  const options = {
    filter: false,
    onRowsDelete: (rowsDeleted) => {
      const array = rowsDeleted.data;
      array.forEach(({ rowIndex, dataIndex }) => {
        console.log(rowIndex, dataIndex);
        const pushNotification_id = pushNotificationArr[dataIndex]._id;
        console.log("ID IS :", pushNotification_id);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}api/push-notifications/delete-pushNotification/${pushNotification_id}`
          )
          .then((res) => {
            console.log("RESPONSE IS", res);
            console.log("Category Successfully deleted");
          });
      });
    },
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            fontFamily: "Montserrat !Important",
            color: "#000",
            fontSize: 20,
            fontWeight: 700,
            fontVariant: "small-caps",
            letterSpacing: "2px",
          },
        },
        MuiTableCell: {
          root: {
            fontSize: 16,
            fontVariant: "small-caps",
            fontFamily: "Montserrat !Important",
          },
        },
        MaterialDatatableToolbar: {
          root: {
            fontFamily: "Montserrat !Important",
            background: "#F2F2F2",
          },
        },

        MuiTableHead: {
          root: {
            fontWeight: 700,
            fontSize: 16,
          },
        },
        MaterialDatatableToolbarSelect: {
          deleteIcon: {
            color: "red",
          },
          title: {
            color: "#000 !important",
          },
        },

        MuiIconButton: {
          root: {
            color: "#2B2828",
          },
        },
      },
    });

  return (
    <>
      <div className={classes.root}>
        <NavDrawer />
        <div className={classes.content}>
          <div className={classes.main}>
            <Typography className={classes.pushNotifyTitle} variant="h4">
              Push Notification Management
            </Typography>
            <Button
              variant="contained"
              size="large"
              className={classes.addBtn}
              onClick={() => {
                history.push("/add-new-notification");
              }}
            >
              New Notification
            </Button>
          </div>
          <div className={classes.main}>
            <div style={{ width: "100%" }}>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MaterialDatatable
                  title={"Push Notification Management"}
                  data={pushNotificationArr}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PushNotifications;
